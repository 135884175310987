@import "../../libs/variables";
@import "../../libs/mediaqueire";
@import "../../libs/animation";

.buttonicon {
  background: transparent;
  border: none;
  padding: 0;
  // cursor: pointer;
}

.professor_teaching_icon {
  color: $aquamarine;
  font-size: 4em;
  @include response-media(medium){
    font-size: 3em;
  }
}

.dollar_program_icon {
  color: $pumpkin;
  font-size: 4em;
  @include response-media(medium){
    font-size: 3em;
  }
}

.methodology_icon{
  color: white;
  font-size: 6em;

}

.play_icon{
  color: white;
  font-size: 1em;
  cursor: pointer;
}

.whatsapp_icon{
  font-size: 3em;
  color: green;
  // background: green;
  cursor: pointer;

}

.email_icon{
  font-size: 2.8em;
  color: white;
 
  border-radius: .3em;
  cursor: pointer;

}

.back_icon{
  font-size: 3em;
  color: white;
  cursor: pointer;
}

.next_icon{
  width: 15vh;
  height: 5vh;
  font-size: 3em;
  border: 2px solid black;
  
  // color: white;
  color: black;
  // background: green;
  border-radius: 1em;
  cursor: pointer;
}

.check_icon{
  font-size: 1.2em;
  color: green;
  // border: 2px solid black;
}

.menu_icon {
  font-size: 2.5em;
  color: white;
  cursor: pointer;
}

.google_icon{
  font-size: 1em;
  color: white;

}

.print_icon{
  font-size: 2.2em;
  cursor: pointer;
}

.plus_icon {
  font-size: 1.6em;
  color: $aquamarinedark;
  cursor: pointer;
  // margin-right: .3em;
  // border: 1px solid $aquamarinedark;
}
.arrow_down_icon{
  font-size: 1.5em;
  color: white;
  cursor: pointer;
  @include keyframes-response(opacityani);
  @include animated("opacityani 1.5s ease-in-out");
}