@import "../../libs/mediaqueire";
@import "../../libs/variables";
@import "../../libs/animation";

.menu_container {
  width: 100%;
  height: 13vh;
  background: black;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo_container {
  width: 15vh;
  height: auto;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  @include response-media(small){
    img{
        width: 10vh;
    }
  }
}

/***********************
Navigation-Menu
************************/
.navigation_container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black with 60% transparency */
  display: flex;
  flex-flow: column;
  // align-items: center;
  @include response-media(small) {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.navigation_close_wrapper {
  width: 100%;
  height: 12vh;
  // background-color: $aquamarine;
  display: flex;
  align-items: center;
  justify-content:space-between;
  border-bottom: 2px solid white;
  h1{
    color: white;
    font-size: 1.5em;
  }
  @include response-media(small) {
    margin-bottom: 1em;
  }
}

.navigation_main_menu {
  width: 80%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-flow: row;
  align-items: center;
  // justify-content: center;
  // justify-content: space-between;
  @include response-media(medium) {
    justify-content: space-evenly;
  }

  @include response-media(small) {
    width: 100%;
    // align-items: none;
    justify-content: normal;
    flex-flow: column;
  }
}
.navigation_buttons {
  width: auto;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 1em;
  font-size: .8em;
  letter-spacing: .1em;
  text-transform: uppercase;

  transition: border 0.5s ease-in-out;
  span{
    margin-left: .5em;
  }

  &:hover {
    border-bottom: 2px solid rgba(255, 255, 255, 0.8);
  }
  @include response-media(medium) {
    margin-left: 0;

    align-items: none;
    font-size: 0.8em;
    
    
    // justify-content: center;
    // flex-flow: column;
  }
  // @include response-media(xsmall) {
  //   align-items: center;
 
  //   font-size: .75em;

  //   // justify-content: center;
  //   // flex-flow: column;
  // }
  // @include response-media(medium) {
  //   color: black;
  // }
}

///////////////////////////////////////
/// Login Mobile 

.login_mobile {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // position: fixed;
  // background-image: url("../../miscellaneous/images/empiremobile.webp");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
}

// top menu bar
.mobile_wrapper00 {
  width: 100%;
  height: 11vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 2px solid red ;
  overflow: hidden;
  background: black;
  .mobileimg_wrapper {
    width: 85%;
    display: flex;
    justify-content: space-between;
  }

 
  // @include response-media(medium) {
  //   img {
  //     height: 30vh;
  //   }
  // }
}
///////////////////////////
/// background
.mobile_wrapper01 {
  width: 93%;
  height: 70vh;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  background: black;
  border: 1px solid $color003;
  position: absolute;
  top: 12%;
  @include keyframes-response(opacityani);
  @include animated("opacityani 1s ease-in-out");
  .mobile_login_path{
    width: 20vh;
    height: 18vh;
    background: black;
    position: absolute;
    font-size: .9em;
    border: none;
    bottom: 10%;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    img {
      width: 80%;
      height: auto;
    }
    // left: 30%;
    // right: 30%;
    
    

  }
 
  // border: 2px solid red ;
}

.mobile_button{
  width: auto;
  // height: 5vh;
  padding: .6em 1.3em;
  background-color: $color002;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}
// login box
.mobile_box00 {
  width: 83%;
  height: 40vh;
  // border: 2px solid black;
  background: $color003;
  position: absolute;
  top: 9.5%;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  @include keyframes-response(opacityani);
  @include animated("opacityani 1.6s ease-in-out");
  h4{
    width: 90%;
    text-align: center;
    // margin-bottom: 2em;
    font-size: 1em;
    margin-top: 1em;
    color: white;
    font-weight: 200;
    letter-spacing: .3px;
    margin: 0;
   
  }
  .mobile_box001{
    width: 95%;
    margin-top: .5em;
    h5{
      margin: 0;
      color: white;
      font-weight: 200;
    }
// background-color: black;

  }

  .mobile_box001_vermas{
    display: flex;
    cursor: pointer;
    @include keyframes-response(opacityani);
    @include animated("opacityani 1.5s ease-in-out");
  }
 
  .box01{
    width: 90%;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
  }
}

// lower bar
.mobile_wrapper02 {
  width: 100%;
  height: 11vh;
  background: black;

  // border: 2px solid red ;
}

.navigation_ {
}
.navigation_ {
}

.navigation_ {
}
.navigation_ {
}
.navigation_ {
}
.navigation_ {
}
.navigation_ {
}
